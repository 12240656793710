import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {HamBurger} from '../../components/ui';
import {flex, media} from '../../components/styled/utils';
import {rgba} from '../utils';
import {Contact, FontChanger, SocialMobile} from '../../components';
import {images} from '../../config';
import onClickOutside from 'react-onclickoutside';

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDropdownOpen: false, theme: null,
        };

        this.selectedMun = this.props.subDomainData.api_id;
        this.url = this.props.subDomainData.url;
        this.title = this.props.subDomainData.title;
    }

    componentDidMount() {
        this.onRouteChanged();
    }

    toggleDropdown = () => {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen,
        });
    };

    handleClickOutside = () => {
        if (this.state.isDropdownOpen) {
            this.setState({
                isDropdownOpen: false,
            });
        }
    };

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        if (this.props.location.pathname.includes('badapatra')) {
            this.setState({
                theme: 'purple',
            });
        } else {
            this.setState({
                theme: null,
            });
        }
    }

    getUrl = site => {
        const {subDomainData} = this.props;

        const isProd = process.env.NODE_ENV === 'production' && !window.location.hostname.includes('staging');

        const http = isProd ? 'https://' : 'http://';
        const isStaging = isProd ? '' : 'staging';
        const siteUrl = `${http}${site}${isStaging && '.' + isStaging}.susasan.org`;

        if (site === 'ims') {
            return isProd ? `https://ims.susasan.org/${subDomainData.title_eng}` : `https://ims.staging.yipl.com.np/${subDomainData.title_eng}`;
        } else if (site === 'data' || site === 'library') {
            if(subDomainData.isSegregated){
                console.log(subDomainData.segregatedUrls[site]);
              return subDomainData.segregatedUrls[site];
            }
            return `${siteUrl}/?mun=${subDomainData.title}`;
        } else if (site === 'grms') {
            const isStaging = isProd ? '' : 'staging';
            return `http://${site}${isStaging && '.' + isStaging}.susasan.org/${subDomainData.title_eng}`;
        }
        return siteUrl;
    };

    render() {
        const {subDomainData} = this.props;
        const {isDropdownOpen, theme} = this.state;
        let hosts = window.location.host.split('.');
        let eprofile = hosts[0] === "eprofile";

        return (<Content>
                <div className={theme ? 'global-bar purple' : 'global-bar'}>
                    <div onClick={this.toggleDropdown}>
                        <HamBurger
                            isOpen={isDropdownOpen}
                            color="#fff"
                            widthLine={16}
                            heightLine={2}
                        />
                    </div>

                    <div className="nav">
                        <NavLink
                            to="/"
                            className="content-left"
                            onClick={this.handleClickOutside}
                        >
                            <img
                                src={images.governmentLogo}
                                alt="Government logo"
                                className="gov-logo"
                            />
                            <span>{subDomainData.title}</span>
                            <span>{subDomainData.municipality}</span>
                        </NavLink>
                        <div className="content-right">
                            <Contact
                                onToggleContact={this.handleClickOutside}
                                selectedMun={this.selectedMun}
                                url={this.url}
                                title={this.title}
                                contact={subDomainData.contact}
                            />
                            <SocialMobile/>
                            <FontChanger/>
                        </div>
                    </div>
                </div>
                <div className={`global-dropdown ${isDropdownOpen ? 'visible' : ''}`}>
                    <div className="global-dropdown__left">
                        <div className="app-list">

                            <NavLink
                                to="/badapatra"
                                className="app-block"
                                onClick={this.handleClickOutside}
                            >
                                <h4 className="title">नागरिक वडापत्र</h4>
                                <p className="desc">
                                    तपाईले पालिका एवं वडा कार्यालयहरुबाट प्रदान गरिने विभिन्न
                                    प्रकारका सेवाहरु बारेमा जानकारी राप्त गर्न सक्नुहुनेछ ।
                                </p>
                            </NavLink>

                            {!eprofile && <NavLink
                                to="/eprofile"
                                className="app-block"
                                onClick={this.handleClickOutside}
                            >
                                <h4 className="title">बस्तुगत विवरण (ई-प्रोफाईल)</h4>
                                <p className="desc">
                                    {subDomainData.municipality}
                                    काे वस्तुगत विवरण सम्बन्धी तथ्यांक, विविध आकर्षणका केन्द्रहरु
                                    र साेकाे विवरण समेत नक्सामा हेर्न सकिन्छ ।
                                </p>
                            </NavLink>}


                            <NavLink
                                to="/mun-detail"
                                className="app-block"
                                onClick={this.handleClickOutside}
                            >
                                <h4 className="title">
                                    {subDomainData.title} {subDomainData.municipality}
                                    सँग सम्बन्धित जानकारीहरु{' '}
                                </h4>
                                <p className="desc">
                                    {subDomainData.municipality}
                                    सँग सम्बन्धित विभिन्न महत्वपूर्ण एवं उपयाेगी जानकारी उपलब्ध
                                    छन् ।
                                </p>
                            </NavLink>

                            <NavLink
                                to="/mun-detail/sdg"
                                className="app-block"
                                onClick={this.handleClickOutside}
                            >
                                <h4 className="title">दिगाे विकास लक्ष्य चित्रण</h4>
                                <p className="desc">
                                    दीगाे विकास लक्ष्य अन्तर्गत १७ वटा लक्ष्यहरुलाई स्थानिय सरकारका वार्षिक याेजना तथा
                                    कार्यक्रमकाे संख्या र साेकाे बजेट/लागतका आधारमा तुलना गरी मेल खाएकाे प्रतिशत (%) लाई
                                    देखाइएकाे छ ।
                                </p>
                            </NavLink>

                            <NavLink
                                to="/mun-voice"
                                className="app-block"
                                onClick={this.handleClickOutside}
                            >
                                <h4 className="title">जनतासँग जनप्रतिनिधि</h4>
                                <p className="desc">
                                    {subDomainData.municipality}
                                    का प्रमुख वा जिम्मेवार अधिकारिबाट नगरपालिकाका गतिविधिहरु एवं
                                    विभिन्न सार्वजनिक सराेकारका विषयहरुबारे विवरण भिडियाेकाे
                                    माध्यमबाट हेर्न सकिनेछ ।
                                </p>
                            </NavLink>
                        </div>
                        {(subDomainData.api_id === 3 || subDomainData.api_id === 4 || subDomainData.api_id === 6 || subDomainData.api_id === 8 || subDomainData.api_id === 7 || subDomainData.api_id === 9 || subDomainData.api_id === 10 || subDomainData.api_id === 11 || subDomainData.api_id === 12) && (
                            <a
                                href={`https://play.google.com/store/apps/details?id=org.susasan.${subDomainData.title_eng}`}
                                className="app-block playstore"
                            >
                                <h4 className="title">
                                    पालिकाकाे माेबाइल एप डाउनलाेड गर्नुहाेस्{' '}
                                </h4>
                                <div>
                                    <p className="desc">
                                        तपाईकाे स्थानिय सरकारका बारेमा एवं यसले सञ्चालन गर्ने
                                        गतिविधिबारे सम्पूर्ण जानकारी अाफ्नाे माेबाइल फाेनमा{' '}
                                        {subDomainData.municipality}काे माेबाइल एपमा प्राप्त गर्न
                                        सक्नुहुनेछ ।
                                    </p>
                                    <img src={images.playStore} alt="playstore"/>
                                </div>
                            </a>)}
                    </div>

                    <div className="global-dropdown__right">
                        {(subDomainData.api_id === 5 || subDomainData.api_id === 6 || subDomainData.api_id === 3 || subDomainData.api_id === 4 || subDomainData.api_id === 10) && (
                            <a href={this.getUrl('grms')} className="app-block">
                                <h4 className="title">गुनासो व्यवस्थापन प्रणाली</h4>
                                <p className="desc">
                                    गुनासाे व्यवस्थापन प्रणाली साफेँबगर नगरपालिकाले विभिन्न
                                    विषयहरुमा नागरिकका गुनासाे/उजुरी प्राप्त गरी साेकाे व्यवस्थित
                                    ढंगबाट सुनुवाई गर्नका लागि लागू गरेकाे प्रणाली हाे ।
                                </p>
                            </a>)}
                        <a href={this.getUrl('library')} className="app-block">
                            <h4 className="title">लाईब्रेरी - Document Library</h4>
                            <p className="desc">
                                डकुमेण्ट लाईब्रेरी अन्तर्गत तपाईले स्थानिय सरकार तथा अन्यबाट
                                प्रकाशित भएका दस्तावेजहरु हेर्न सक्नुहुन्छ ।
                            </p>
                        </a>
                        <a href={this.getUrl('data')} className="app-block">
                            <h4 className="title">डाटा संग्रह - Data Hub</h4>
                            <p className="desc">
                                डाटा संग्रह अन्तर्गत तपाईले स्थानिय सरकार तथा अन्यबाट प्रकाशित
                                कुनै पनि खुला ढाँचा (जस्तैः CSV, EXCEL आदि) मा रहेका तथ्यांकहरु
                                हेर्न सक्नुहुन्छ ।
                            </p>
                        </a>
                        {subDomainData.api_id === 2 && (<a href={this.getUrl('ims')} className="app-block playstore">
                                <h4 className="title">पूर्वाधार व्यवस्थापन</h4>
                                <div/>
                                <p className="desc">
                                    स्थानिय सरकारले आफ्नाे क्षेत्रमा सञ्चालित पूर्वाधार विकासका
                                    परियाेजनाकाे स्थितिबारे जानकारी अद्यावधिक गरिएकाे प्रणाली हाे
                                    ।
                                </p>
                            </a>)}
                    </div>
                </div>
                {/* <div className={`overlay ${isDropdownOpen ? 'visible' : ''}`} /> */}
            </Content>);
    }
}

export default withRouter(onClickOutside(Navbar));

const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 9999999;
  .global-bar {
    background-image: linear-gradient(
        to bottom,
        rgba(1, 73, 131, 0.5),
        rgba(0, 0, 0, 0.5)
      ),
      linear-gradient(to bottom, #00529b, #00529b);
    position: relative;
    z-index: 3;
    ${flex('row', null, 'center')};

    &.purple {
      background-image: linear-gradient(
          to bottom,
          rgba(1, 73, 131, 0.5),
          rgba(0, 0, 0, 0.5)
        ),
        linear-gradient(to bottom, #350073, #350073);
    }

    .ham-burger {
      ${flex(null, 'center', 'center')};
      cursor: pointer;
      width: 60px;
      height: 60px;
      border-right: 1px solid ${rgba('#979797', 0.23)};
      background-color: transparent;
      transition: background-color 0.2s ease-in;

      &:hover {
        background-color: #1b5790;
      }
    }

    .nav {
      ${flex('row', 'space-between', 'center')};
      flex: 1;
      padding: 0 24px;

      .content-left {
        text-decoration: none;
        line-height: 1.2;
        display: flex;
        align-items: center;

        .gov-logo {
          height: 40px;
          margin-right: 8px;
        }

        span {
          color: #fff;
          font-size: 1.26rem;

          &:last-child {
            opacity: 0.5;
            margin-left: 4px;
          }
        }
      }

      .content-right {
        ${flex('row', null, 'center')};

        > div,
        > ul {
          margin-right: 12px;

          ${media.tablet`
            margin-right: 24px;
          `};
        }

        .app-logo {
          opacity: 0.7;
          transition: opacity 0.2s ease-in;

          ${media.tablet`
            margin-right: 28px;
          `};

          img {
            height: 20px;
            display: block;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .global-dropdown {
    position: absolute;
    background-color: #fff;
    z-index: 2;
    width: 100%;
    opacity: 0;
    top: 60px;
    visibility: hidden;
    transition: all 0s ease-in;
    box-shadow: 0 2px 25px rgba(2, 68, 121, 0.2);

    @media screen and (max-width: 768px) {
      height: calc(100vh - 60px);
      overflow-y: scroll;
    }

    ${media.tablet`
      ${flex('row', null, null)};
    `};

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    .title {
      font-weight: 600;
      margin-bottom: 8px;
    }

    .app-block {
      box-sizing: border-box;
      border: 1px solid rgba(0, 82, 155, 0.039);
      transition: all 0.2s ease-in;
      cursor: pointer;
      padding: 12px;
      text-decoration: none;
      display: block;
      cursor: pointer;

      ${media.tablet`
        padding: 32px;
      `};

      ${media.giant`
        padding: 32px 180px 32px 86px;
      `};
      .desc {
        color: ${rgba('#4c565e', 0.8)};
      }

      &:hover {
        background-color: #e8f4ff;
        box-shadow: 0 2px 7px 0 rgba(0, 80, 157, 0.13);
        .title {
          color: #028eff;
        }
      }
    }

    &__left {
      ${flex('column', null, null)};
      background-color: #f0f8ff;

      text-decoration: none;

      ${media.tablet`
        width: 60%;
      `};

      ${media.desktop`
        width: 70%;
      `};

      .app-list {
        display: flex;
        flex-wrap: wrap;
      }

      .app-block {
        ${media.phoneWide`
        width: 50%;
      `};

        &.playstore {
          width: 100%;
          flex: 1;
          padding: 18px 12px;

          ${media.tablet`
            padding: 64px 32px;
          `};

          ${media.giant`
            padding: 64px 180px 64px 86px;
          `};

          > div {
            display: flex;
            flex-direction: column;

            ${media.tablet`
            flex-direction: row;
            `};

            ${media.desktop`
              width: 60%;
            `};

            .desc {
              margin: 0 18px 12px 0;
            }
          }
        }
      }

      .title {
        color: ${props => props.theme.primary.neelo};
      }
    }
    &__right {
      background-color: #e8f4ff;

      display: flex;
      flex-wrap: wrap;

      .app-block {
        @media screen and (min-width: 480px) and (max-width: 768px) {
          width: 50%;
        }
      }

      ${media.tablet`
        width: 40%;
      `};

      ${media.desktop`
        width: 30%;
      `};

      .title {
        color: #000e1a;
      }
    }
  }

  .overlay {
    transition: all 0.2s ease-in;
    &.visible {
      background-color: rgba(0, 0, 0, 0.78);
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  @media print {
    display: none;
  }
`;
