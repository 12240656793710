// let url =
//   process.env.NODE_ENV === 'production' &&
//   !window.location.hostname.includes('staging')
//     ? 'https://cms.susasan.org'
//     : 'https://eprofile.staging.yipl.com.np/';
let url = 'https://cms.susasan.org';
exports.sanfebagar = {
  title: 'साँफेबगर',
  title_eng: 'sanfebagar',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  imagePath: 'assets/sanfebagar/images',
  csvPath: 'assets/sanfebagar/files',
  district: 'अछाम',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/1_VlbpNjqCH3dIbIKigZq74ejLo3zO9o_J11w9UArzUM/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1dorEdGOycnN0YHRb7mpG1GLzN2YMNNJrk4kwVxUhXqQ/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1h29bJW-IPTrPMKCVAiF77E6FWnWsaVKwHs9AS3oAXdc/edit?usp=sharing',
  api_id: 5,
  gps: [29.229, 81.2188],
  kobo_id: 9,
  kobo_district_id: 5,
  wards: 14,
  analyticsCode: 'UA-113618013-2',
  contact: {
    name: 'साफेँबगर नगर कार्यपालिकाको कार्यालय',
    phn_no: '०७१-५५५५५५६',
    fax_no: '',
    post_box_no: '',
    ward_no: '१',
    email: [],
  },
  isSegregated: false,
};
exports.masta = {
  title: 'मष्टा',
  title_eng: 'masta',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/masta/images',
  csvPath: '',
  district: 'बझाङ',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/1dh2TXKl71r4o9x2WQh5NUfGIHUkILgcb7dpI7w_-YCQ/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1QFKS5V_9sUh__kHpi5ZuDqE27RajzFJJ9NA50YoEwjs/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/16MDyxILy1wC7Ki59-ARPkeyFC-hrqSC-2hfKFnN2Dus/edit?usp=sharing',
  api_id: 8,
  gps: [29.556145, 81.24365333333334],
  kobo_id: 12,
  kobo_district_id: 6,
  wards: 7,
  analyticsCode: 'UA-113618013-15',
  contact: {
    name: 'मष्टा गाउँ कार्यपालिकाकाे कार्यालय',
    phn_no: '',
    fax_no: '',
    post_box_no: '',
    ward_no: '४',
    email: ['mastarural.mum@gmail.com'],
  },
  isSegregated: false,
};
exports.konjyosom = {
  title: 'कोन्ज्योसोम',
  title_eng: 'konjyosom',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/konjyosom/images',
  csvPath: 'assets/konjyosom/files',
  district: 'ललितपूर',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/1TfAhgoeRuGNKKUrmSepoI-kbRSd3mSJRioIMMk-DqM0/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1s3zPUftrYstGtZ9i1Q5Lqh2YbXDe2pFV8uAKRxhJGic/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1CoghTbYqyWLHXtylKgvwJFSfNKD4QNzk8QNirb6YRps/edit?usp=sharing',
  api_id: 12,
  gps: [27.53768, 85.36775000000002],
  kobo_id: 1,
  kobo_district_id: 1,
  wards: 5,
  analyticsCode: 'UA-113618013-5',
  contact: {
    name: 'कोन्ज्योसोम गाउँ कार्यपालिकाकाे कार्यालय',
    phn_no: '९८५११६८२५०',
    fax_no: '',
    post_box_no: '',
    ward_no: '१',
    email: [],
  },
  isSegregated: false,
};
exports.bagmati = {
  title: 'बागमति',
  title_eng: 'bagmati',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/bagmati/images',
  csvPath: 'assets/bagmati/files',
  district: 'ललितपूर',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/1Boj-MCcDI2Fdd_S_P0ZNjqmcYgxDxahk1idbAViIoRc/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1VdH5y_BiHp0kYpw36pjidw6vzmaHzr4WyTP_EVVKFWU/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1NbiPsUy84QfLSLn1nvwdESIENfC2FgKsRXWsbjBakRA/edit?usp=sharing',
  api_id: 11,
  gps: [27.53768, 85.36775000000002],
  kobo_id: 2,
  kobo_district_id: 1,
  wards: 7,
  analyticsCode: 'UA-113618013-6',
  contact: {
    name: 'वाग्मती गाउँ कार्यपालिकाकाे कार्यालय',
    phn_no: '९८४१३३५५८५',
    fax_no: '',
    post_box_no: '',
    ward_no: '३',
    email: [],
  },
  isSegregated: false,
};
exports.barhabise = {
  title: 'बाह्रविसे',
  title_eng: 'barhabise',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  imagePath: 'assets/masta/images',
  csvPath: 'assets/barhabise/files',
  district: 'सिन्धुपाल्चाेक',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/1zFZLTkoOkmmNymnkTnvIZFpq1Jn97Wl0tJGzXz_SvwQ/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1EiiT2g_1Sw0em0kRCgcJV000N5G5_fnzTuKkiA7UtYg/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1schG-_YhkhLRqOTU6kvkhmoC5qEpHFah3sM7Q9kRWko/edit?usp=sharin',
  api_id: 9,
  gps: [27.788373333333332, 85.90003],
  kobo_id: 3,
  kobo_district_id: 2,
  wards: 9,
  analyticsCode: 'UA-113618013-8',
  contact: {
    name: 'बाह्रबिसे नगर कार्यपालिकाकाे कार्यालय',
    phn_no: '०११-४८९००३',
    fax_no: '',
    post_box_no: '',
    ward_no: '',
    email: ['barhabisemun@gmail.com'],
  },
  isSegregated: false,
};
exports.sunkoshi = {
  title: 'सुनकाेशी',
  title_eng: 'sunkoshi',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/masta/images',
  csvPath: '',
  district: 'सिन्धुपाल्चाेक',
  // policiesUrl:'',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1GWSKJxHQCZntp7Rm34FuhimWcm6-S_GnVtc1rqlMc2Y/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1Uw7uC0C4Bu3O-jSKO0DNVRMfMrMmsc3rmTOQX6vQmyk/edit?usp=sharing',
  api_id: 10,
  gps: [27.68566, 85.78117],
  kobo_id: 4,
  kobo_district_id: 2,
  wards: 7,
  analyticsCode: 'UA-113618013-7',
  contact: {
    name: 'सुनकाेशी गाउँ कार्यपालिकाकाे कार्यालय',
    phn_no: '०११-४८२२०५',
    fax_no: '',
    post_box_no: '',
    ward_no: '',
    email: [],
  },
  isSegregated: false,
};
exports.amargadhi = {
  title: 'अमरगढी',
  title_eng: 'amargadhi',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  imagePath: 'assets/amargadhi/images',
  csvPath: 'assets/amargadhi/files',
  district: 'डडेलधुरा',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/1Lj_vCXsMjg8i_E8tnXepx5y75o8dsqjUggJpoMR1u-U/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1hZkBqBV21rlZYHYVcQYOsf34bMC_2ZWO_pX_xlnLrW4/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1IsUyb-O4XRnq8QROODbwcJ4lXi3D_ZKZRVtuRC7j5y8/edit?usp=sharing',
  api_id: 3,
  gps: [29.306931666666667, 80.59246333333334],
  kobo_id: 7,
  kobo_district_id: 4,
  wards: 11,
  analyticsCode: 'UA-113618013-11',
  contact: {
    name: 'अमरगढी नगर कार्यपालिकाकाे कार्यालय',
    phn_no: '०९६-४१०२२२, ०९६-४१००२२',
    fax_no: '',
    post_box_no: '',
    ward_no: '५',
    email: ['info@amargadhimun.gov.np', 'ict.amargadhimun@gmail.com'],
  },
  isSegregated: false,
};
exports.nawadurga = {
  title: 'नवदूर्गा',
  title_eng: 'nawadurga',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/masta/images',
  csvPath: '',
  district: 'डडेलधुरा',
  url,
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/17qRAuNKvDLZrpZqhn9Egv49jZyAMMT-O1fRjag-o3Vo/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1G8mrVTL2f2syJ46A1B3CMvNEMflXwwqCyQglNQPmfZ0/edit?usp=sharing',
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1D6o2JA1lzHsaz60DbA58MzGgoMLUL1lRgrzcEJoov8Y/edit?usp=sharing',
  api_id: 4,
  gps: [29.3398812, 80.66848228],
  kobo_id: 8,
  kobo_district_id: 4,
  wards: 5,
  analyticsCode: 'UA-113618013-12',
  contact: {
    name: 'नवदुर्गा गाउँ कार्यपालिकाकाे कार्यालय',
    phn_no: '९८५८७५१४३५',
    fax_no: '',
    post_box_no: '',
    ward_no: '२',
    email: ['nawadurgaruralmun@gmail.com'],
  },
  isSegregated: false,
};
exports.dhangadhi = {
  title: 'धनगढी',
  title_eng: 'dhangadhi',
  municipality: 'उपमहानगरपालिका',
  council: 'नगरसभा',
  imagePath: 'assets/dhangadhi/images',
  csvPath: 'assets/dhangadhi/files',
  district: 'कैलाली',
  url,
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/12InQt_txSVrNa_907Kl-D6T-Hy0YtNp1ajCn3qaiR3Q/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/17JWg2yjzD-bVVDBB0ZLijylqcrbREfko4HdXXGY0lA8/edit?usp=sharing',
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1aCoa6OTSsH0FtlGikhP9svXup8NPqMVqNBNi40kfsp8/edit?usp=sharing',
  api_id: 2,
  gps: [28.707231666666665, 80.58549],
  kobo_id: 5,
  kobo_district_id: 3,
  wards: 19,
  analyticsCode: 'UA-113618013-9',
  contact: {
    name: 'धनगढी उप-महानगर कार्यपालिकाकाे कार्यालय',
    phn_no: '०९१-५२१५२९,०९१-५२०७३३,०९१-५२१४२९',
    fax_no: '०९१-५२१५२९',
    post_box_no: '',
    ward_no: '',
    email: ['info@dhangadhimun.gov.np', 'dhangadhimun2013@gmail.com'],
  },
  isSegregated: false,
};
exports.kailari = {
  title: 'कैलारी',
  title_eng: 'kailari',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/kailari/images',
  csvPath: 'assets/kailari/files',
  district: 'कैलाली',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/1XUZ0REqHljPUCLOLTK4U-1suOyrilGSsULgR7yUXpL8/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1CtWBEFybjQMEfs8A0DVeagAVBiaFxJeLZBO8rfg-w30/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1N27zAkgtL5EkOwx94rPZvWmfhp-tXH7IqUHH2iAs9es/edit?usp=sharing',
  api_id: 1,
  gps: [28.568213333333333, 80.79507666666667],
  kobo_id: 6,
  kobo_district_id: 3,
  wards: 9,
  analyticsCode: 'UA-113618013-10',
  contact: {
    name: 'कैलारी गाउँ कार्यपालिकाकाे कार्यालय',
    phn_no: '९८५८४२०७३७',
    fax_no: '',
    post_box_no: '',
    ward_no: '१',
    email: ['vdc.hasuliya@gmail.com', 'kailarigaupalika@gmail.com'],
  },
  isSegregated: false,
};
exports.bannigadhijayagadh = {
  title: 'बान्नीगढी जयगढ',
  title_eng: 'bannigadhijayagadh',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/bannigadhijayagadh/images',
  csvPath: 'assets/bannigadhijayagadh/files',
  district: 'अछाम',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/1Zmw4_qD5t07BuqNHchJRqlJLmQfkfNau4x8yNSQRFkQ/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1bh3sHWKRTSDKP_309dzWtx3Sg7IrH9LFx2bYZ1HUBSE/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1JB3nuPtpTHv9me2IMrOux_c4BdJK_c0ipMkzY30VaBM/edit?usp=sharing',
  api_id: 6,
  gps: [29.20010166666667, 81.27986],
  kobo_id: 10,
  kobo_district_id: 5,
  wards: 6,
  analyticsCode: 'UA-113618013-13',
  contact: {
    name: 'बन्निगदी जयगढ गाउँ कार्यपालिकाकाे कार्यालय',
    phn_no: '९८४८४३८०६३',
    fax_no: '',
    post_box_no: '',
    ward_no: '३',
    email: [],
  },
  isSegregated: false,
};
exports.jayaprithivi = {
  title: 'जयपृथ्वी',
  title_eng: 'jayaprithivi',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  imagePath: 'assets/jayaprithivi/images',
  csvPath: 'assets/jayaprithivi/files',
  district: 'बझाङ',
  policiesUrl:
    'https://docs.google.com/spreadsheets/d/12CDx_0C1QVzANhlDBPMzgUWFXh3OpEqfC8EiXg04SfE/edit?usp=sharing',
  policiesBudgetUrl:
    'https://docs.google.com/spreadsheets/d/1BilqxXqa0MRezw8L54f9XKvCxQepPnvNEUqzSoLzsAw/edit?usp=sharing',
  url,
  covidUrl:
    'https://docs.google.com/spreadsheets/d/1O5dfCpjMbEFN96jZRbJ0wJVNrK6qWagMKFYAwZjLcGA/edit?usp=sharing',
  api_id: 7,
  gps: [29.54345399357996, 81.19046737321945],
  kobo_id: 11,
  kobo_district_id: 6,
  wards: 11,
  analyticsCode: 'UA-113618013-14',
  contact: {
    name: 'जयपृथ्वी नगर कार्यपालिकाकाे कार्यालय',
    phn_no: '०९२-४२१०६४',
    fax_no: '०९२-४२१०६४',
    post_box_no: '',
    ward_no: '१०',
    email: ['jpmunicipality2014@gmail.com'],
  },
  isSegregated: false,
};
exports.jugal = {
  title: 'जुगल',
  title_eng: 'jugal',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'सिन्धुपाल्चाेक',
  url,
  api_id: 16,
  gps: [27.83979617, 85.7740145],
  isSegregated: false,
};
exports.ajayameru = {
  title: 'अजयमेरु',
  title_eng: 'ajayameru',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'डडेलधुरा',
  url,
  api_id: 15,
  gps: [29.333412, 80.521869],
  isSegregated: false,
};
exports.mellekh = {
  title: 'मेल्लेख',
  title_eng: 'mellekh',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'अछाम',
  url,
  api_id: 14,
  gps: [29.25461, 81.283445],
  isSegregated: true,
  segregatedUrls: {
    library: 'https://library.mellekhmun.gov.np',
    data: 'https://data.mellekhmun.gov.np?mun=मेल्लेख',
  },
};
exports.mellekhmun = {
  title: 'मेल्लेख',
  title_eng: 'mellekh',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'अछाम',
  url,
  api_id: 14,
  kobo_id: 15,
  kobo_district_id: 5,
  gps: [29.25461, 81.283445],
  isSegregated: true,
  segregatedUrls: {
    library: 'https://library.mellekhmun.gov.np',
    data: 'https://data.mellekhmun.gov.np?mun=मेल्लेख',
  },
};

exports.chaurpati = {
  title: 'चौरपाटी',
  title_eng: 'chaurpati',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'अछाम',
  url,
  api_id: 17,
  gps: [29.188214, 81.144465],
  isSegregated: false,
};
exports.khaptadchhanna = {
  title: 'खप्तडछान्ना',
  title_eng: 'khaptadchhanna',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'बझाङ',
  url,
  api_id: 19,
  gps: [29.54345399357996, 81.19046737321945],
  isSegregated: false,
};
exports.parshuram = {
  title: 'परशुराम',
  title_eng: 'parshuram',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'डडेलधुरा',
  url,
  api_id: 20,
  gps: [29.54345399357996, 81.19046737321945],
  isSegregated: false,
};
exports.durgathali = {
  title: 'दुर्गाथली',
  title_eng: 'durgathali',
  municipality: 'गाउँपालिका',
  council: 'गाउँसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'बझाङ',
  url,
  api_id: 18,
  gps: [29.54345399357996, 81.19046737321945],
  isSegregated: false,
};
exports.gauriganga = {
  title: 'गौरीगंगा',
  title_eng: 'gauriganga',
  municipality: 'नगरपालिका',
  council: 'नगरसभा',
  imagePath: 'assets/jugal/images',
  csvPath: 'assets/jugal/files',
  district: 'कैलाली',
  url,
  api_id: 21,
  gps: [28.767679, 80.754832],
  isSegregated: false,
};
